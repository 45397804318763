import React, { useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import colorSharp1 from "../assets/img/color-sharp1.png";
import Aos from 'aos';
import 'aos/dist/aos.css';

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const [percentageList, setPercentageList] = useState({
    'Developers & Engineers': 58,
    'Managment': 4,
    'Business Development': 8,
    'Project Managers': 10,
    'Business Analysts': 6,
    'Designers': 8,
    'QA and Testers': 6,
  });
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, [])
  return (
    <section className="skill" >
      <div className="container">
        <div className="row" >
          <div className="col-12">
            <div className="skill-bx wow zoomIn" id="team">
              <h2>Our Team</h2>
              <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider" autoPlay={true}
                autoPlaySpeed={2000}>
                {Object.entries(percentageList).map(([category, percentage]) => (
                  <div data-aos="fade-right" className='item progress-bar-item' key={category}>
                    <div className="progress-bar-container">
                      <CircularProgressbar
                        value={percentage}
                        text={`${percentage}%`}
                        styles={buildStyles({
                          pathColor: `#267BF0`,
                          textColor: '#fff',
                          trailColor: '#000',
                          strokeLinecap: 'round',
                          textSize: '14px',
                          pathTransitionDuration: 0.5,
                          pathTransition: 'none',
                        })}
                      />
                    </div>
                    <h5>{category}</h5>
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <img className="background-image-left" src={colorSharp1} alt="Image" />
    </section>
  );
};