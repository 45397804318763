import { useState, useEffect } from "react";
import { Navbar,Nav,Container } from "react-bootstrap";
import { BoxArrowLeft } from 'react-bootstrap-icons';
import logo from '../assets/img/dtsLogo.png';
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/nav-icon2.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';
import { BrowserRouter as Router } from "react-router-dom";

export const NavBarService = () => {
  const [setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }

  return (
    <Router>
      <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
        <Container >
        <Navbar.Brand href="/">
  <img
    src={logo}
    style={{
      height: "100px",
      width: "100px",
      filter: "brightness(140%)",
      transform: "perspective(200px) rotateX(-10deg) rotateY(10deg)",
    }}
  />
</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav" style={{justifyContent:'flex-end'}}>
            <span className="navbar-text">
              <div className="social-icon">
                <a href="https://www.linkedin.com/company/dynamics-technology-systems/"><img src={navIcon1} alt="" /></a>
                <a href="https://www.facebook.com/DynamicsTechnologySystems"><img src={navIcon2} alt="" /></a>
                <a href="https://www.instagram.com/dynamicstechnologysystems/"><img src={navIcon3} alt="" /></a>
              </div>
              <Nav.Link href='/'>
                <button className="vvd"><span><BoxArrowLeft size={25} /></span></button>
              </Nav.Link>
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Router>
  )
}
