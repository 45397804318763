import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/ai.png";
import pseb from "../assets/img/pseb.png";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import {  Nav} from "react-bootstrap";

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = ["Web Developers", "Web Designers", "Artificial Intelligence Developers", "Mobile App Developers"];
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <span className="tagline">Welcome to New World of IT!</span>
                  <h1>{`We are`} <span className="txt-rotate" dataPeriod="200" data-rotate='[ "Web Developers", "Web Designers", "Artificial Intelligence Developers", "Mobile App Developers" ]'><span className="wrap">{text}</span></span></h1>
                  <p>
                    Dynamics Technology Systems (DTS) is a privately-owned, innovative company based in Pakistan with over 25 years of experience in the IT industry. DTS is dedicated to serving people through the application of cutting-edge technologies. They prioritize client satisfaction and offer professional IT services, including web design & development, software development, POS, and attendance solutions. The team at DTS comprises skilled engineers and professionals who welcome new and challenging projects, emphasizing quality and punctuality in their work.</p>
                  <h3>Registered With: </h3>
                  <p>Pakistan Software Export Board (PSEB) Registration number:<br/> Z-25-6968/22</p>
                  <img src={pseb} className="psb" alt="image-pseb"></img>
                  <Nav.Link href="#connect"><button className="mb-4">Let’s Connect <ArrowRightCircle size={25} /></button></Nav.Link>
                </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={headerImg} alt="Header Img" className="bannerImg" />
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
