import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/contact-img.svg";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import Aos from 'aos';
import 'aos/dist/aos.css';

export const Contact = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, [])
  const formInitialDetails = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  }
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState('Send');
  const [status, setStatus] = useState({ message: '', success: null });
  const [submitted, setSubmitted] = useState(false);

  const validatePhone = (phone) => {
    const phoneRegex = /^\d{11}$/;
    return phoneRegex.test(phone);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    setButtonText("Sending...");

    if (!formDetails.firstName || !formDetails.lastName || !formDetails.email || !formDetails.phone || !formDetails.message) {
      setStatus({ success: false, message: 'Please fill in all fields.' });
      setButtonText("Send");
      return;
    } else if (!validateEmail(formDetails.email)) {
      setStatus({ success: false, message: 'Please enter a valid email address.' });
      setButtonText("Send");
      return;
    } else if (!validatePhone(formDetails.phone)) {
      setStatus({ success: false, message: 'Please enter a valid phone number.' });
      setButtonText("Send");
      return;
    }
    
  try {
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);
    const response = await fetch('submit_contact.php', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    });

    const result = await response.text();
    alert(result);

    if (response.ok) {
      setStatus({ success: true, message: 'Message sent successfully' });
      setFormDetails(formInitialDetails); // Reset form fields
      setSubmitted(false);
    } else {
      setStatus({ success: false, message: 'Something went wrong, please try again later.' });
    }
  } catch (error) {
    setStatus({ success: false, message: 'Error occurred while sending the message.' });
  } finally {
    setButtonText("Send");
  }
};

  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <img className={isVisible ? "animate__animated animate__zoomIn" : ""} src={contactImg} alt="Contact Us" />
              }
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <h2>Get In Touch</h2>
                  <form onSubmit={handleSubmit} id="contactForm">
                    <Row>
                      <Col size={12} sm={6} className="px-1" style={{ marginBottom: '15px' }}>
                        <input type="text" id="first_name" name="first_name" value={formDetails.firstName} placeholder="First Name" onChange={(e) => onFormUpdate('firstName', e.target.value)} />
                        {submitted && status.message && !formDetails.firstName && <p className="text-warning">Please enter your first name.</p>}
                      </Col>
                      <Col size={12} sm={6} className="px-1" style={{ marginBottom: '15px' }}>
                        <input type="text" id="last_name" name="last_name"  value={formDetails.lastName} placeholder="Last Name" onChange={(e) => onFormUpdate('lastName', e.target.value)} />
                        {submitted && status.message && !formDetails.lastName && <p className="text-warning">Please enter your last name.</p>}
                      </Col>
                      <Col size={12} sm={6} className="px-1" style={{ marginBottom: '15px' }}>
                        <input type="email" id="email_address" name="email_address"  value={formDetails.email} placeholder="Email Address" onChange={(e) => onFormUpdate('email', e.target.value)} />
                        {submitted && status.message && !formDetails.email && <p className="text-warning">Please enter your email address.</p>}
                        {submitted && status.message && formDetails.email && !validateEmail(formDetails.email) && <p className="text-warning">Please enter a valid email address.</p>}
                      </Col>
                      <Col size={12} sm={6} className="px-1" style={{ marginBottom: '15px' }}>
                        <input type="tel" id="phone_no" name="phone_no"  value={formDetails.phone} placeholder="Phone No." onChange={(e) => onFormUpdate('phone', e.target.value)} />
                        {submitted && status.message && !formDetails.phone && <p className="text-warning">Please enter your phone number.</p>}
                        {submitted && status.message && formDetails.phone && !validatePhone(formDetails.phone) && <p className="text-warning">Please enter a valid phone number.</p>}
                      </Col>
                      <Col size={12} className="px-1">
                        <textarea rows="6" id="message" name="message"  value={formDetails.message} placeholder="Message" onChange={(e) => onFormUpdate('message', e.target.value)}></textarea>
                        {submitted && status.message && !formDetails.message && <p className="text-warning">Please enter your message.</p>}
                        <button type="submit"><span>{buttonText}</span></button>
                        {status.success && <h5 className="mt-4 success">Message sent successfully!</h5>}
                        {submitted && status.message && !status.success && <h5 className="mt-4 text-warning">{status.message}</h5>}
                      </Col>
                      {/* {
                        status.message &&
                        <Col>
                          <p className={status.success === false ? "danger" : "success"}>{status.message}</p>
                        </Col>
                      } */}
                    </Row>
                  </form>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}