import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Switch, Route } from "react-router-dom";
import LandingPage from './pages/LandingPage';
import Web from './pages/Web';
import Mobile from './pages/Mobile';
import Software from './pages/Software';
import AI from './pages/AI';
import IT from './pages/IT';
import TimeAttendence from './pages/TimeAttendence';
import { ContactPage } from './pages/ContactPage';

function App() {
  return (
 
        <Switch>
          <Route exact path="/" >
            <LandingPage />
          </Route>
          <Route path="/web-designing">
            <Web />
          </Route>
          <Route path="/mobile-app">
            <Mobile />
          </Route>
          <Route path="/software-development">
            <Software />
          </Route>
          <Route path="/artificial-intelligence">
            <AI />
          </Route>
          <Route path="/it-infrastructure">
            <IT/>
          </Route>
          <Route path="/time-attendence">
            <TimeAttendence/>
          </Route>
          <Route path="/contact">
            <ContactPage/>
          </Route>
        </Switch>

  );
}

export default App;