import { Container, Row, Col } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import proj1 from "../assets/img/project1.png";
import proj2 from "../assets/img/project2.png";
import proj3 from "../assets/img/project3.png";
import proj4 from "../assets/img/project4.png";
import proj5 from "../assets/img/project5.png";
import proj6 from "../assets/img/project6.png";
import proj7 from "../assets/img/project7.png";
import proj8 from "../assets/img/project8.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import "animate.css";
import TrackVisibility from "react-on-screen";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export const Projects = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const projects = [
    {
      title: "AI Chatbot on Xpertly",
      description: "Artificial Intelligence",
      imgUrl: proj1,
    },
    {
      title: "KWTRO Plants Websites",
      description: "Website Design & Development",
      imgUrl: proj2,
    },
    {
      title:
        "Intelligent Reports & Dashboards with Strategic Impact for MAA International Australia",
      description: "Power BI",
      imgUrl: proj3,
    },
    {
      title:
        "Empowering 'Mazaya Real Estate' by Our Apps Revolutionizing Business Operations",
      description: "Microsoft SharePoint",
      imgUrl: proj4,
    },
    {
      title: "Machinoweb",
      description: "Website Design & Development & Mobile Applications",
      imgUrl: proj5,
    },
    {
      title: "Meals of Mercy",
      description: "Mobile Application",
      imgUrl: proj6,
    },
    {
      title: "MAA International, Australia",
      description: "Kentico",
      imgUrl: proj7,
    },
    {
      title:
        "Managing Business of Non-Profit Organization, MAA International, Australia",
      description: "CRM",
      imgUrl: proj8,
    },
  ];
  const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 4
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <h2>Our Projects</h2>
                  {/* Carousel Component */}
                  {/* <Carousel 
                    responsive={responsive}
                    infinite={true}
                    autoPlay={false}
                    autoPlaySpeed={2000}
                    arrows={true}
                    className="owl-carousel owl-theme project-slider"
                  >
                    {projects.map((project, index) => (
                      <ProjectCard key={index} {...project} />
                    ))}
                  </Carousel> */}
                  <Carousel
                    responsive={responsive}
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={2000}
                    // keyBoardControl={true}
                    // showDots={true}
                    arrows={true}
                    className="owl-carousel owl-theme project-slider custom-carousel"
                  >
                    {projects.map((project, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          width: "100%",
                          marginLeft:'15%',
                          transition: "transform 0.3s ease",
                        }}
                      >
                        <ProjectCard {...project} />
                      </div>
                    ))}
                  </Carousel>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img
        className="background-image-right"
        src={colorSharp2}
        alt="Background"
      />
    </section>
  );
};
