import React, { useEffect, useState } from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";
import Aos from 'aos';
import 'aos/dist/aos.css';

export const Footer = () => {
  const [activeLink, setActiveLink] = useState(null);
  const [hoveredLink, setHoveredLink] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, [])
  const linkStyles = {
    color: activeLink === hoveredLink ? '#6F42C1' : 'white',
    textDecoration: 'none',
    underline: 'none',
    transition: 'color 0.3s ease',
    cursor: 'pointer',
  };
  // Function to handle scrolling to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center justify-content-center "> {/* Changed align-items-center to align-items-start */}
          {/* Column 1 - About DTS */}
          <Col xs={12} sm={6} md={4} className="mt-4 text-center text-md-start">
            <h5 style={{color:'#a6aaff', fontWeight:'bold', fontSize:'23px'}}>About DTS</h5>
            <p style={{ fontSize: '16px' }}>DTS is an innovative company in Pakistan with a clear purpose of serving people using the latest and cutting-edge technologies.</p>
            <div className="social-icon">
              <a href="https://www.linkedin.com/company/dynamics-technology-systems/"><img src={navIcon1} alt="LinkedIn Icon" /></a>
              <a href="https://www.facebook.com/DynamicsTechnologySystems"><img src={navIcon2} alt="Facebook Icon" /></a>
              <a href="https://www.instagram.com/dynamicstechnologysystems/"><img src={navIcon3} alt="Instagram Icon" /></a>
            </div>
          </Col>

          {/* Column 2 - IT Solutions */}
          <Col xs={12} sm={6} md={4} className="mt-4">
            <h5 style={{marginLeft:'15px', color:'#a6aaff', fontWeight:'bold', fontSize:'23px'}}>IT Solutions</h5>
            <ul style={{ listStyleType: 'none', padding: 0 }}>
              <li>
                <Nav.Link
                  href="/it-infrastructure" // Use the "to" prop for the link path
                  style={{ ...linkStyles, color: hoveredLink === 1 ? '#a6aaff' : 'white' }}
                  onClick={() => setActiveLink(1)}
                  onMouseEnter={() => setHoveredLink(1)}
                  onMouseLeave={() => setHoveredLink(null)}
                >
                  IT Infrastructure Design
                </Nav.Link>
              </li>
              <li>
                <Nav.Link
                  href="/web-designing"
                  style={{ ...linkStyles, color: hoveredLink === 2 ? '#a6aaff' : 'white' }}
                  onClick={() => setActiveLink(2)}
                  onMouseEnter={() => setHoveredLink(2)}
                  onMouseLeave={() => setHoveredLink(null)}
                >
                  Web Designing & Development
                </Nav.Link>
              </li>
              <li>
                <Nav.Link
                  href="/software-development"
                  style={{ ...linkStyles, color: hoveredLink === 3 ? '#a6aaff' : 'white' }}
                  onClick={() => setActiveLink(3)}
                  onMouseEnter={() => setHoveredLink(3)}
                  onMouseLeave={() => setHoveredLink(null)}
                >
                  Software Development
                </Nav.Link>
              </li>
            </ul>
          </Col>

          {/* Column 3 - Business Solutions */}
          <Col xs={12} sm={6} md={4} className="mt-4">
            <h5 style={{marginLeft:'15px', marginTop:'-35px', color:'#a6aaff', fontWeight:'bold', fontSize:'23px'}}
            >Business Solutions</h5>
            <ul
              style={{ listStyleType: 'none', padding: 0 }}>
              <li>
                <Nav.Link
                  href="/time-attendence"
                  style={{ ...linkStyles, color: hoveredLink === 4 ? '#a6aaff' : 'white' }}
                  onClick={() => setActiveLink(4)}
                  onMouseEnter={() => setHoveredLink(4)}
                  onMouseLeave={() => setHoveredLink(null)}
                >
                  Time & Attendance Solutions
                </Nav.Link>
              </li>
              <li>
                <Nav.Link
                  href="/mobile-app"
                  style={{ ...linkStyles, color: hoveredLink === 5 ? '#a6aaff' : 'white' }}
                  onClick={() => setActiveLink(5)}
                  onMouseEnter={() => setHoveredLink(5)}
                  onMouseLeave={() => setHoveredLink(null)}
                >
                  Mobile Apps
                </Nav.Link>
              </li>
            </ul>
          </Col>
        </Row>

        {/* Logo and Social Icons */}
        <Row className="align-items-center justify-content-center mt-4"> {/* Added mt-4 to create space */}
          <Col xs={12} sm={6} className="text-center text-sm-end">
            <p>
              <span style={{ fontWeight: 'bold' }}>Dynamics Technology Systems</span>{" "}
              © Copyright 2024. All Rights Reserved
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
