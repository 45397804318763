import service1 from '../assets/img/service1.png'
import service2 from '../assets/img/service2.png'
import service3 from '../assets/img/service3.png'
import service4 from '../assets/img/service4.png'
import service5 from '../assets/img/service5.png'
import service6 from '../assets/img/service6.png'
import Carousel from 'react-multi-carousel';
import { useEffect } from 'react'
import {Nav} from "react-bootstrap";
import 'react-multi-carousel/lib/styles.css';
import Aos from 'aos';
import 'aos/dist/aos.css';

export const Services = () => {
    useEffect(() => {
        Aos.init({ duration:2000 });
     }, [])
    const carouselItems = [
        {
            title: 'Web Designing & Development',
            image: service1,
            slug: '/web-designing',
            altText: 'servicesImage',
        },
        {
            title: 'Mobile App Development',
            image: service2,
            slug: '/mobile-app',
            altText: 'servicesImage',
        },
        {
            title: 'Software Development',
            image: service4,
            slug: '/software-development',
            altText: 'servicesImage',
        },
        {
            title: 'Artificial Intelligence',
            image: service3,
            slug: '/artificial-intelligence',
            altText: 'servicesImage',
        },
        {
            title: 'IT Infrastructure Design',
            image: service5,
            slug: '/it-infrastructure',
            altText: 'servicesImage',
        },
        {
            title: 'Time & Attendance Solutions',
            image: service6,
            slug: '/time-attendence',
            altText: 'servicesImage',
        }
    ];
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 4
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        <section className="services" id="services">
            <h2>Our Services</h2>
            <p>Dynamics Technology Systems deliver dynamic IT services and solutions for business requirements. We are equipped with highly qualified and experienced professionals with all the required skills sets and ability to handle any project.
            Dynamics Technology Systems offer a wide range of IT solutions and services including software development, mobile application development, web design & development, Artificial Intelligence and IT infrastructure design.</p>
            <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme service-slider" 
                autoPlay={true}
                autoPlaySpeed={2000}>
                {carouselItems.map((item, i) => (
                    <div className="item" key={i}>
                        <Nav.Link href={item.slug} className="service-link">
                        <img src={item.image} alt={item.altText} />
                        <h5>{item.title}</h5>
                        </Nav.Link>
                    </div>
                ))}
            </Carousel>
        </section>
    )
}
