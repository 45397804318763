import client1 from '../assets/img/client1.webp'
import client2 from '../assets/img/client2.png'
import client3 from '../assets/img/client3.jpeg'
import client4 from '../assets/img/client4.png'
import client5 from '../assets/img/client5.png'
import client6 from '../assets/img/client6.jpeg'
import client7 from '../assets/img/client7.jpeg'
import client8 from '../assets/img/client8.png'
import client9 from '../assets/img/client9.png'
import client10 from '../assets/img/client10.png'
import client11 from '../assets/img/client11.png'
import client12 from '../assets/img/client12.jpeg'
import client13 from '../assets/img/client13.jpeg'
import client14 from '../assets/img/client14.png'
import client15 from '../assets/img/client15.jpeg'
import client16 from '../assets/img/client16.png'
import client17 from '../assets/img/client17.png'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react'
import {Nav} from "react-bootstrap";

export const Clients = () => {
    useEffect(() => {
        Aos.init({ duration:2000 });
     }, [])
    const carouselItems = [
        {
            image: client1,
            altText: 'clientLogo'
        },
        {
            image: client2,
            altText: 'clientLogo'
        },
        {
            image: client3,
            altText: 'clientLogo'
        },
        {
            image: client4,
            altText: 'clientLogo'
        },
        {
            image: client5,
            altText: 'clientLogo'
        },
        {
            image: client6,
            altText: 'clientLogo'
        },
        {
            image: client7,
            altText: 'clientLogo'
        },
        {
            image: client8,
            altText: 'clientLogo'
        },
        {
            image: client9, // Replace with your image path
            altText: 'clientLogo'
        },
        {
            image: client10,
            altText: 'clientLogo'
        },
        {
            image: client11,
            altText: 'clientLogo'
        },
        {
            image: client12,
            altText: 'clientLogo'
        },
        {
            image: client13,
            altText: 'clientLogo'
        },
        {
            image: client14,
            altText: 'clientLogo'
        },
        {
            image: client15,
            altText: 'clientLogo'
        },
        {
            image: client16,
            altText: 'clientLogo'
        },
        {
            image: client17,
            altText: 'clientLogo'
        },
    ];
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 4
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        <section className="client" id="client">
            <h2>Our Clients</h2>
            <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme client-slider" 
                autoPlay={true}
                autoPlaySpeed={2000}>
                {carouselItems.map((item, i) => (
                    // <div className="item" key={i}>
                    //     <img src={item.image} alt={item.altText} />
                    // </div>
                    <div className="item" key={i}>
                        <Nav.Link href={item.slug} className="service-link">
                        <img src={item.image} alt={item.altText} />
                        <h5>{item.title}</h5>
                        </Nav.Link>
                    </div>
                ))}
            </Carousel>
        </section>
    )
}
