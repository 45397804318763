import React from 'react'
import { Footer } from '../components/Footer'
import headerImg from "../assets/img/banner4.png";
import BannerService from '../components/BannerService'
import { NavBarService } from '../components/NavbarService';
import { Services } from '../components/Services';

const AI = () => {
  return (
    <>
    <NavBarService/>
    <BannerService heading = "Artificial Intelligence" imageSrc = {headerImg} paragraph = "Dynamics Technology Systems leads AI innovation through the seamless integration of advanced Large Language Models like BERT and GPT. Our expertise extends to fine-tuning, enabling customized solutions for diverse applications including text generation, sentiment analysis, and more. Rooted in excellence, we provide comprehensive training programs, unlocking your ability to fully harness the potential of these state-of-the-art models."/>
    <Services/>
    <Footer/>
    </>
  )
}

export default AI