import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from '../assets/img/dtsLogo.png';
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/nav-icon2.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';

export const NavBar = () => {
  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }

  return (
    <Navbar expand="md" className={scrolled ? "scrolled" : ""} fixed="top">
      <Container>
        <Navbar.Brand href="/">
          <img
            src={logo}
            alt="companyLogo"
            className="d-inline-block align-top"
            style={{
              height: "60px",
              width: "auto",
              filter: "brightness(140%)",
              transform: "perspective(200px) rotateX(-10deg) rotateY(10deg)"
            }}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <span className="navbar-toggler-icon"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>Home</Nav.Link>
            <Nav.Link href="#about" className={activeLink === 'about' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('about')}>About Us</Nav.Link>
            <Nav.Link href="#services" className={activeLink === 'services' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('services')}>Services</Nav.Link>
            <Nav.Link href="#team" className={activeLink === 'team' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('team')}>Team</Nav.Link>
            <Nav.Link href="#client" className={activeLink === 'client' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('client')}>Client</Nav.Link>
            <Nav.Link href="#projects" className={activeLink === 'projects' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('projects')}>Projects</Nav.Link>
          </Nav>
          <span className="navbar-text d-flex align-items-center flex-wrap">
            <div className="social-icon d-flex justify-content-center">
              <a href="https://www.linkedin.com/company/dynamics-technology-systems/">
                <img src={navIcon1} alt="linkedinLink" className="social-icon-img" />
              </a>
              <a href="https://www.facebook.com/DynamicsTechnologySystems">
                <img src={navIcon2} alt="facebookLink" className="social-icon-img" />
              </a>
              <a href="https://www.instagram.com/dynamicstechnologysystems/">
                <img src={navIcon3} alt="instagramLink" className="social-icon-img" />
              </a>
            </div>
            <Nav.Link href='/contact'>
              <button className="vvd"><span>Let’s Connect</span></button>
            </Nav.Link>
          </span>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
